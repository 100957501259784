import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Pages layout
import { Menu } from './pages/layouts/menu';
import { Signin } from './pages/components/auth/signin';
import { ForgotPassword } from './pages/components/auth/forgotPassword';

import { AuthProvider, AuthRoute, PrivateRoute } from './context/AuthProvider.js'

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/signin" element={<Signin />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/*" element={<AuthRoute><Menu /></AuthRoute>} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
