import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate, Link, NavLink, useParams, useLocation } from 'react-router-dom';

import { isMobile, isTablet } from 'react-device-detect';

import { useDropzone } from 'react-dropzone';

//services
import galleryService from '../../../services/gallery'

//auth
import { useAuth } from '../../../context/AuthProvider'
//validation form
function StepImages() {
    const logo = require('../../../assets/img/logo/logo.png');

    //get id
    const { id } = useParams();

    const location = useLocation();
    //back
    const navigate = useNavigate();
    function goBack() {
        navigate(-1);
    }
    //validation auth
    const auth = useAuth();

    //message error
    const [message, setMessage] = useState(null);
    //alert
    const [showAlert, setShowAlert] = useState(true);
    //modal
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false) }
    const handleShow = () => setShow(true);
    const [savedImages, setSavedImages] = useState([]);
    const [infoGalleryGet, setInfoGalleryGet] = useState('');

    useEffect(() => {
        onGetGalleryInfo();
    }, []);
    const onGetGalleryInfo = async () => {
        let bearer_Token = {
            'headers': {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${auth.authToken()}`
            }
        };
        try {
            const getImagesGallery = await galleryService.getAllImagesGallery(id, bearer_Token);
            console.log(getImagesGallery.data.gallery.images)
            if (getImagesGallery.status === 200 && getImagesGallery.data.statusCode === 200) {
                setSavedImages(getImagesGallery.data.gallery.images);
                setInfoGalleryGet(getImagesGallery.data.gallery)

            } else {
                document.getElementById('item_image').value = '';
                setShowAlert(true);
                setMessage({ error: true, msg: "An error has occurred! try again" });
            }
        } catch (error) {

        }
    }
    const onDrop = (acceptedFiles) => {
        const imageFiles = acceptedFiles.filter(file => file.type.startsWith('image/'));
        if (imageFiles.length > 0) {
            onSaveImages(imageFiles);
        } else {
            setShowAlert(true);
            setMessage({ error: true, msg: "only images are accepted" });
        }

    };
    const onSaveImages = async (files) => {
        const formData = new FormData();
        files.forEach(file => {
            formData.append('files', file);
        });

        if (files) {
            let bearer_Token = {
                'headers': {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${auth.authToken()}`
                }
            };
            try {
                const uploadImage = await galleryService.saveImageArr(id, formData, bearer_Token);
                if (uploadImage.status === 200 && uploadImage.data.statusCode === 201) {
                    onGetGalleryInfo();
                } else {
                    document.getElementById('item_image').value = '';
                    setShowAlert(true);
                    setMessage({ error: true, msg: "An error has occurred! try again" });
                }
            } catch (error) {
                if (error.response.status === 401) {
                    auth.logout()
                } else {
                    setShowAlert(true);
                    setMessage({ error: true, msg: "An error has occurred! try again" });
                }
            }
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*', // Aceptar solo imágenes
        multiple: true,   // Permitir múltiples archivos
    });

    const dropzoneStyle = {
        border: '2px dashed #cccccc',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
    };

    const handleDeleteImage = async (index,imgId) => {
        if (imgId) {
            let bearer_Token = {
                'headers': {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${auth.authToken()}`
                }
            };
            try {
                const delteImage = await galleryService.deleteImageGallery(imgId, bearer_Token);
                
                if (delteImage.status === 200 && delteImage.data.statusCode === 200) {
                    const newImages = [...savedImages];
                    newImages.splice(index, 1);
                    setSavedImages(newImages);
                    onGetGalleryInfo();
                } else {
                    setShowAlert(true);
                    setMessage({ error: true, msg: "An error has occurred! try again" });
                }
            } catch (error) {
                if (error.response.status === 401) {
                    auth.logout()
                } else {
                    setShowAlert(true);
                    setMessage({ error: true, msg: "An error has occurred! try again" });
                }
            }
        }
        
      
    };

    return (
        <>
            <div className='container content-cms'>
                <div className='row gallery-images'>
                    <div className='text-center'>
                        {message ?
                            <div className={`alert alert-dismissible fade show alert-${message.error ? 'danger' : 'success'}`} role="alert">
                                <strong>{message.msg}</strong>
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                            : null
                        }
                    </div>
                    <div className='text-center mb-4'>
                        <h1>gallery: {infoGalleryGet.galName}</h1>
                    </div>
                    <div className='mb-3' {...getRootProps()} style={dropzoneStyle}>
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                                <p>Suelta los archivos aquí...</p> :
                                <p>Arrastra y suelta archivos aquí, o haz clic para seleccionar</p>
                        }
                    </div>
                    <div className='text-center mb-3'>
                        {/* Mostrar las imágenes ya guardadas */}
                        {Array.isArray(savedImages) ? (

                        savedImages.map((imageUrl, index) => (
                            <div key={index} className='d-inline-block position-relative'>
                                <img src={imageUrl.imgUrl} alt={`Imagen ${index + 1}`} className='img-thumbnail m-2' style={{ maxWidth: '200px' }} />
                                <button
                                    className='btn btn-danger btn-sm position-absolute top-0 end-0 m-2'
                                    onClick={() => handleDeleteImage(index,imageUrl.imgId)}
                                >
                                    <i className="fa-solid fa-trash"></i>
                                </button>
                            </div>
                        ))
                       ) :(<></>
                       )}
                    </div>
                    <div className='text-center'>
                       <NavLink to={'/gallery'}><button type="submit" className="btn btn-save w-50">finish <i className="fa-solid fa-flag-checkered"></i></button></NavLink> 
                    </div>
                </div>
            </div>
        </>
    )
}
export { StepImages }