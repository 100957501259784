import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Routes, Route, Link, NavLink, useParams, useLocation } from 'react-router-dom';

import { isMobile, isTablet } from 'react-device-detect';

//services
import CategoryService from '../../../services/category'
import PlaceService from '../../../services/place'

//auth
import { useAuth } from '../../../context/AuthProvider'

function Category() {

    const location = useLocation();

    //validation auth
    const auth = useAuth();

    //get category
    const [categoryGet, setCategoryGet] = useState('');


    useEffect(() => {
        onLoadCategory();
    }, []);


    //get first load places
    const onLoadCategory = async () => {
        try {
            let bearer_Token = {
                'headers': {

                    'Authorization': `Bearer ${auth.authToken()}`
                }
            };
            const getAllCategory = await CategoryService.getAllCategory(bearer_Token);
            if (getAllCategory.status === 200 && getAllCategory.data.statusCode === 201) {
                setCategoryGet(getAllCategory.data.data);
            } else if (getAllCategory.status === 200 && getAllCategory.data.statusCode === 404) {
            } else if (getAllCategory.status === 200 && getAllCategory.data.output.statusCode === 400) {
            } else {
            }
        } catch (error) {
            console.log(error.response.status)
        }
    }
    return (
        <>
            <div className='container pd-4'>
                <h4 className="text-center">CATEGORIES</h4>
                <div className='row'>
                    <div className='col-6'>
                        <div className="btn-group" role="group">
                            <Link to={'/new-category'} type="button" className="btn btn-outline-success btn-sm">New <i className="fa-solid fa-plus"></i></Link>
                        </div>
                    </div>
                    <div className='pd-4'>
                        <table className="table table-hover">
                            <thead>
                                <tr className='table-dark'>
                                    <th scope="col">Image</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Tags</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(categoryGet) ? (
                                    categoryGet.map((item, i) => (
                                        <tr key={i}>
                                            <th><img src={item.catImage} /></th>
                                            <td className='text-table'>{item.catName}</td>
                                            <td className='text-table'>{item.catDescription.slice(0, 25)}...</td>
                                            <td><span className={`badge  ${item.catActive === '1' ? 'text-bg-success' : 'text-bg-secondary'}`}>{item.catActive === '1' ? 'active' : 'disabled'} </span></td>
                                            <td className='text-table'>{item.plaName}</td>
                                            <td><div className="btn-group" role="group">
                                                <Link to={`/edit-category/${item.catId}`} type="button" className="btn btn-warning btn-sm">Edit <i className="fa-solid fa-pen"></i></Link>
                                            </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr className='placeholder-glow'>
                                        <th ><span className="placeholder col-12"></span></th>
                                        <td ><span className="placeholder col-12"></span></td>
                                        <td ><span className="placeholder col-12"></span></td>
                                        <td > <span className="placeholder col-12"></span></td>
                                        <td ><span className="placeholder col-12"></span> </td>
                                        <td ><span className="placeholder col-12"></span> </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
export { Category }