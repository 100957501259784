import React, { useState, useEffect, useRef } from 'react';
import { Routes, Route, Link, NavLink, useParams, useLocation } from 'react-router-dom';

import { isMobile, isTablet } from 'react-device-detect';

//services
import VideographyService from '../../../services/videography'
import PlaceService from '../../../services/place'

//auth
import { useAuth } from '../../../context/AuthProvider'
import VideoThumbnail from 'react-video-thumbnail';

function Videography() {

    const location = useLocation();

    //validation auth
    const auth = useAuth();

    //get category
    const [videographyGet, setVideographyGet] = useState('');


    useEffect(() => {
        onLoadVideography();
    }, []);


    //get first load places
    const onLoadVideography = async () => {
        try {
            let bearer_Token = {
                'headers': {
                    'Authorization': `Bearer ${auth.authToken()}`
                }
            };
            const getAllVideography = await VideographyService.getVideography(bearer_Token);
            if (getAllVideography.status === 200 && getAllVideography.data.statusCode === 201) {
                console.log(getAllVideography.data.data)
                setVideographyGet(getAllVideography.data.data);
            } else if (getAllVideography.status === 200 && getAllVideography.data.statusCode === 404) {
            } else if (getAllVideography.status === 200 && getAllVideography.data.output.statusCode === 400) {
            } else {
            }
        } catch (error) {
            console.log(error.response.status)
        }
    }
    const getRandomTime = () => {
        return Math.floor(Math.random() * 10) + 1; // Genera un número aleatorio entre 1 y 10
    };


    return (
        <>
            <div className='container pd-4'>
                <h4 className="text-center">VIDEOGRAPHY</h4>
                <div className='row'>
                    <div className='col-6'>
                        <div className="btn-group" role="group">
                            <Link to={'/new-videography'} type="button" className="btn btn-outline-success btn-sm">New <i className="fa-solid fa-plus"></i></Link>
                        </div>
                    </div>
                    <div className='pd-4'>
                        <table className="table table-hover">
                            <thead>
                                <tr className='table-dark'>
                                    <th scope="col">Image</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Tags</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(videographyGet) ? (
                                    videographyGet.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <th>
                                                <img src={item.vidThumbnailUrl} alt="Thumbnail Preview" /> 
                                                </th>
                                                <td className='text-table'>{item.vidName}</td>
                                                <td><span className={`badge  ${item.vidActive === '1' ? 'text-bg-success' : 'text-bg-secondary'}`}>{item.catActive === '1' ? 'active' : 'disabled'} </span></td>
                                                <td className='text-table'>{item.plaName}</td>
                                                <td><div className="btn-group" role="group">
                                                    <Link to={`/edit-category/${item.vidId}`} type="button" className="btn btn-warning btn-sm">Edit <i className="fa-solid fa-pen"></i></Link>
                                                </div>
                                                </td>
                                            </tr>
                                        )
                                    }

                                    )
                                ) : (
                                    <tr className='placeholder-glow'>
                                        <th ><span className="placeholder col-12"></span></th>
                                        <td ><span className="placeholder col-12"></span></td>
                                        <td ><span className="placeholder col-12"></span></td>
                                        <td > <span className="placeholder col-12"></span></td>
                                        <td ><span className="placeholder col-12"></span> </td>
                                        <td ><span className="placeholder col-12"></span> </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
export { Videography }