import React, { useContext, useState } from "react";

import http from "../configs/http.config";

class AuthService {

    login(data) {
        return http.post(`/auth/login`, data);
    }
    recovery(data,autorization) {
        return http.post(`/auth/login/${data}`, autorization);
    }
}
export default new AuthService();