import React, { useContext, useState } from "react";

import http from "../configs/http.config";

class CategoryService {

    saveImage(data,Authorization) {
        return http.post(`/category/image`, data,Authorization);
    }
    newCategory(data,Authorization) {
        return http.post(`/category/new`, data,Authorization);
    }
    getAllCategory(Authorization) {
        return http.get(`/category/get_cms`,Authorization);
    }
    getByIdCategory(id,Authorization) {
        return http.get(`/category/get_category_cms/${id}`,Authorization);
    }
    updateByIdCategory(id,data,Authorization) {
        return http.patch(`/category/update_category/${id}`,data,Authorization);
    }


}
export default new CategoryService();