import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Routes, Route, Link, NavLink, useParams, useLocation, useNavigate } from 'react-router-dom';

import { isMobile, isTablet } from 'react-device-detect';
//formik
import { Field, useFormik, Formik } from "formik";
import * as Yup from "yup";

//services
import AuthService from '../../../services/auth.js'
import { useAuth } from '../../../context/AuthProvider.js';

//validation forms
const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email()
        .required(" Required"),
    password: Yup.string()
        .required(" No password provided")
        .min(8, "Password is too short - should be 8 chars minimum.")
        .matches(/(?=.*[0-9])/, "Password must contain a number")
});

function Signin() {

    //images
    const logo = require('../../../assets/img/logo/logo.png');
    //naviagte
    const navigate = useNavigate();
    const location = useLocation();

    //messages alert
    const [message, setMessage] = useState(null);

    //auth context
    const auth = useAuth();

    //log in bd
    const onLogin = async (values) => {
        try {
            let data = {
                email: values.email,
                password: values.password
            }
            const login = await AuthService.login(data);
            console.log(login)

            if (login.status === 200 & login.data.userEmail === values.email && login.data.roles === 'admin') {
                setMessage({ error: false, msg: "Login successfully ... redirecting ... " });

                //rendirect log in
                const accessToken = login.data?.accessToken;
                sessionStorage.setItem("auth", JSON.stringify({  roles: login.data?.roles, accessToken: accessToken }));


                setTimeout(() => {
                    auth.loginOnNew(login.userEmail);
                    //veryfi direction to log in
                    if (!location.state?.from) {
                        navigate('/');
                    } else {
                        navigate(location.state.from);
                    }
                }, 2000);

            } else if (login.status === 200 & login.data.statusCode === 404) {
                //not found
                setMessage({ error: true, msg: "Email incorrect! try again" });
            } else if (login.status === 200 & login.data.statusCode === 401) {
                //unautorized
                setMessage({ error: true, msg: "Password incorrect! try again" });
            } else {
                setMessage({ error: true, msg: "you don't have permissions" });
            }
        } catch (error) {
            setMessage({ error: true, msg: "An error has occurred!" });
        }
    }
    //formik
    const formik = useFormik({
        initialValues: { email: "", password: "" },
        validateOnBlur: true,
        onSubmit: (values) => {
            onLogin(values);
        },
        validationSchema: validationSchema,
    });


    return (
        <>
            <div className='container'>
                <div className='row'>
                    <main className="form-signin w-100 m-auto ">
                        <form onSubmit={formik.handleSubmit}>
                            <div className='text-center'>
                                <img className="mb-4 img-fluid" src={logo} alt="" />
                                <h1 className="h3 mb-3 fw-normal">Please sign in</h1>
                                {message ?
                                    <div className={`alert alert-dismissible fade show alert-${message.error ? 'danger' : 'success'}`} role="alert">
                                        <strong>{message.msg}</strong>
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>
                                    : null
                                }
                            </div>
                            <div className="form-floating">
                                <input type="email" className="form-control" placeholder="name@example.com"
                                    id="email" name='email'
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />
                                <label htmlFor="Email">
                                    {formik.errors.email && formik.touched.email
                                        ? <div className="text-danger">Email address{formik.errors.email}</div>
                                        : <div className="">Email address{formik.errors.email}</div>
                                    }
                                </label>
                            </div>
                            <div className="form-floating">
                                <input type="password" className="form-control" placeholder="Password"
                                    id="password" name='password'
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />
                                <label htmlFor="Password">
                                    {formik.errors.password && formik.touched.password
                                        ? <div className="text-danger">Password{formik.errors.password}</div>
                                        : <div className="">Password</div>
                                    }
                                </label>
                            </div>
                            <button disabled={!formik.isValid} className="btn w-100 py-2 mb-2" type="submit">Sign in</button>
                        </form>
                        <NavLink to={'/forgotpassword'} className="mb-5 mt-5 text-body-secondary ">Forgot your Password?</NavLink>
                        <p className="mt-4 mb-3 text-body-secondary text-center copyrigth">Copyrigth &copy; 2019 MAJU CURATED INC, and All Rights Reserved.</p>

                    </main>
                </div>

            </div>


        </>
    )
}
export { Signin }