import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Routes, Route, Link, NavLink, useParams, useLocation } from 'react-router-dom';

import { isMobile, isTablet } from 'react-device-detect';


function ForgotPassword() {

    //images
    const logo = require('../../../assets/img/logo/logo.png');
    /*     const logo = require('../../assets/img/logo/logo.png');
        const togglerBlack = require('../../assets/img/design/icon_menu_gray.png'); */


    const location = useLocation();

    return (
        <>
            <div className='container'>
                <div className='row'>
                    <main className="form-signin w-100 m-auto ">
                        <form>
                            <div className='text-center'>
                                <img className="mb-4 img-fluid" src={logo} alt="" />
                                <h1 className="h3 mb-3 fw-normal">Please Enter Email</h1>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                                <label for="floatingInput">Email address</label>
                            </div>
                            <button className="btn w-100 py-2 mb-2" type="submit">Send</button>
                        </form>
                        <NavLink to={'/signin'} className="mb-5 mt-5 text-body-secondary ">Sign In</NavLink>
                        <p className="mt-5 mb-3 text-body-secondary text-center copyrigth">Copyrigth &copy; 2019 MAJU CURATED INC, and All Rights Reserved.</p>
                    </main>
                </div>
            </div>


        </>
    )
}
export { ForgotPassword }