import React, { useContext, useState } from "react";

import http from "../configs/http.config";

class PlaceService {

    getAllplace(Authorization) {
        return http.get(`/place/cms`,Authorization);
    }
    getAllplaceCms(Authorization) {
        return http.get(`/place/get_cms`,Authorization);
    }
    getAllplaceById(id,Authorization) {
        return http.get(`/place/get_place/${id}`,Authorization);
    }
    newPlace(data,Authorization) {
        return http.post(`/place/new`,data,Authorization);
    }
    saveImage(data,Authorization) {
        return http.post(`/place/new_image`,data,Authorization);
    }
    editPlace(id,data,Authorization) {
        return http.patch(`/place/edit/${id}`,data,Authorization);
    }
  
}
export default new PlaceService();