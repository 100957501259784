import React, { useContext, useState } from "react";

import http from "../configs/http.config";

class VideographyService {

    getVideography(Authorization) {
        return http.get(`/videography/get_cms`, Authorization);
    }
    createVideo(data, Authorization) {
        return http.post(`/videography/video_cms`, data, Authorization);
    }
    createVideography(data, Authorization) {
        return http.post(`/videography/videography_cms`, data, Authorization);
    }

}
export default new VideographyService();