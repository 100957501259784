import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate, Link, NavLink, useParams, useLocation } from 'react-router-dom';

import { isMobile, isTablet } from 'react-device-detect';

//formik
import { Field, useFormik } from "formik";
import * as Yup from "yup";

//bootstrap
import Modal from 'react-bootstrap/Modal';

//services
import galleryService from '../../../services/gallery'
import PlaceService from '../../../services/place'

//auth
import { useAuth } from '../../../context/AuthProvider'
//validation form
const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required("*Required")
        .min(5, "Must be at least 5 characters")
        .max(45, "Cannot exceed 45 characters"),
    description: Yup.string()
        .required("*Required")
        .min(10, "Must be at least 10 characters")
        .max(300, "Cannot exceed 45 characters"),
    categories: Yup.string()
        .required("*Required"),
    place: Yup.string()
        .required("*Required")
});


function NewGallery() {
    const logo = require('../../../assets/img/logo/logo.png');
    const imageDefault = require('../../../assets/img/design/image.png');

    //validation auth
    const auth = useAuth();

    //message error
    const [message, setMessage] = useState(null);
    //alert
    const [showAlert, setShowAlert] = useState(true);

    const [photo, setPhoto] = useState(null);

    const [imageGallery, setimageGallery] = useState('');

    const [getIdResonse, setGetIdResonse] = useState('');

    //places
    const [placeGet, setPlaceGet] = useState('');

    //categories
    const [categoriesGet, setCategoriesGet] = useState('');


    const location = useLocation();
    //back
    const navigate = useNavigate();
    function goBack() {
        navigate(-1);
    }

    //modal
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false) }
    const handleShow = () => setShow(true);

    useEffect(() => {
        onLoadPlace();
        onLoadCategory();
    }, []);

    //get first load places
    const onLoadPlace = async () => {
        try {
            let bearer_Token = {
                'headers': {
                    'Authorization': `Bearer ${auth.authToken()}`
                }
            };
            const getAllPlace = await PlaceService.getAllplace(bearer_Token);
            setPlaceGet(getAllPlace.data);
        } catch (error) {

        }
    }

    //load category
    const onLoadCategory = async () => {
        try {
            let bearer_Token = {
                'headers': {
                    'Authorization': `Bearer ${auth.authToken()}`
                }
            };
            const getAllCategories = await galleryService.getAllCategory(bearer_Token);
            console.log(getAllCategories.data.data)
            setCategoriesGet(getAllCategories.data.data);
        } catch (error) {

        }
    }

    const handlePhotoSelect = (event) => {
        const file = event.target.files[0];
        let error = false;
        if (file && !/^(image\/jpeg|image\/png)$/.test(file.type)) {
            document.getElementById('item_image').value = '';
            setPhoto('');
            error = true;
            setMessage({ error: true, msg: 'The file must be of type PNG or JPG' });
            return;
        }

        const maxSize = 1 * 1024 * 1024; // 5 MB
        if (file && file.size > maxSize) {
            document.getElementById('item_image').value = '';
            setPhoto('');
            error = true;
            setMessage({ error: true, msg: `The file must be less than or equal to  ${maxSize / 1024 / 1024} MB` });
            return;
        }
        setPhoto(event.target.files[0]);

        if (error === false) {
            handleSubmitImage(file);
        }
    }

    const handleSubmitImage = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        if (file) {
            let bearer_Token = {
                'headers': {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${auth.authToken()}`
                }
            };
            try {
                const uploadImage = await galleryService.saveImage(formData, bearer_Token);
                if (uploadImage.status === 200 && uploadImage.data.statusCode === 201) {
                    setimageGallery(uploadImage.data.imaUrl);
                } else {
                    document.getElementById('item_image').value = '';
                    setShowAlert(true);
                    setMessage({ error: true, msg: "An error has occurred! try again" });
                }
            } catch (error) {
                if (error.response.status === 401) {
                    auth.logout()
                } else {
                    setShowAlert(true);
                    setMessage({ error: true, msg: "An error has occurred! try again" });
                }
            }
        }
    };
    const handleSubmit = async (values) => {
        const data = {
            name: values.name,
            description: values.description,
            category: values.categories,
            place: values.place,
            active: values.active,
            image: imageGallery ? imageGallery : 'image',
        };
        try {
            let bearer_Token = {
                'headers': {
                    'Authorization': `Bearer ${auth.authToken()}`
                }
            };
            const createBlog = await galleryService.newGallery(data, bearer_Token);
            if (createBlog.status === 200 && createBlog.data.statusCode === 201) {
                //create blog
                setGetIdResonse(createBlog.data.galId)
                setShow(true);
            } else if (createBlog.status === 200 && createBlog.data.statusCode === 400) {
                //error
                setShowAlert(true);
                setMessage({ error: true, msg: "An error has occurred! try again" });
            } else if (createBlog.status === 200 && createBlog.data.output.statusCode === 401) {
                auth.logout()
            }
        } catch (error) {
            if (error.response.status === 401) {
                auth.logout()
            } else {
                setShowAlert(true);
                setMessage({ error: true, msg: "An error has occurred! try again" });
            }
        }
    }

    //formik
    const formik = useFormik({
        initialValues: { name: "", description: "", categories: "", place: "", active: false, },
        validateOnBlur: true,
        onSubmit: (values) => {
            handleSubmit(values);
        },
        validationSchema: validationSchema,
    });


    return (
        <>
            <div className='container pd-4 content-cms'>
                <h4 className="text-center">NEW Gallery</h4>
                <div className='row'>
                    <div className='text-center'>
                        {message ?
                            <div className={`alert alert-dismissible fade show alert-${message.error ? 'danger' : 'success'}`} role="alert">
                                <strong>{message.msg}</strong>
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                            : null
                        }
                    </div>
                    <div className='col-6'>
                        <div className="btn-group mb-3" role="group">
                            <Link onClick={goBack} type="button" className="btn btn-outline-secondary btn-sm"><i className="fa-solid fa-chevron-left"></i> Back</Link>
                        </div>
                    </div>
                    <div className='pd-4'>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="form-floating mb-3 col-md-12 col-lg-6">
                                <input type="text" className="form-control" placeholder="name@example.com"
                                    id="name" name='name'
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />
                                <label htmlFor="name">
                                    {formik.errors.name && formik.touched.name
                                        ? <div className="text-danger">Name {formik.errors.name}</div>
                                        : <div className="">Name </div>
                                    }
                                </label>
                            </div>
                            <div className="form-floating mb-3 col-md-12 col-lg-6">
                                <textarea type="text" className="form-control" placeholder="name@example.com"
                                    id="description" name='description'
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />
                                <label htmlFor="description">
                                    {formik.errors.description && formik.touched.description
                                        ? <div className="text-danger">Description {formik.errors.description}</div>
                                        : <div className="">Description </div>
                                    }
                                </label>
                            </div>
                            <div className="form-floating mb-3 col-md-12 col-lg-6">
                                <select className="form-select"
                                    id="categories"
                                    name="categories"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.categories}
                                >
                                    <option value="">open this select menu</option>
                                    {Array.isArray(categoriesGet) ? (
                                        categoriesGet.map((item, i) => (
                                            <option key={i} value={item.catId}>{item.catName}</option>
                                        ))
                                    ) : (
                                        <option value="">No categories available</option>
                                    )}
                                </select>
                                <label htmlFor="category">
                                    {formik.errors.categories && formik.touched.categories
                                        ? <div className="text-danger">Please, Select a Cateogry {formik.errors.categories}</div>
                                        : <div className="">Please, Select a Category </div>
                                    }
                                </label>
                            </div>
                            <div className="form-floating mb-3 col-md-12 col-lg-6">
                                <select className="form-select"
                                    id="place"
                                    name="place"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.place}
                                >
                                    <option value="">open this select menu</option>
                                    {Array.isArray(placeGet) ? (
                                        placeGet.map((item, i) => (
                                            <option key={i} value={item.plaId}>{item.plaName}</option>
                                        ))
                                    ) : (
                                        <option value="">No places available</option>
                                    )}
                                </select>
                                <label htmlFor="place">
                                    {formik.errors.place && formik.touched.place
                                        ? <div className="text-danger">Please, Select a place {formik.errors.place}</div>
                                        : <div className="">Please, Select a place </div>
                                    }
                                </label>
                            </div>
                            <div className="mb-3 col-md-12 col-lg-6">
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch"
                                        id="active"
                                        name='active'
                                        checked={formik.values.active}
                                        onChange={formik.handleChange} />
                                    <label className="form-check-label" htmlFor="active">Active</label>
                                </div>
                            </div>
                            <div className="mb-3 col-md-12 col-lg-6">
                                <img src={imageGallery ? imageGallery : imageDefault} className='img-fluid w-50' />
                            </div>
                            <div className="mb-5 ">
                                <label htmlFor="formFile" className="form-label">Please Select Image</label>
                                <input id='item_image' onChange={handlePhotoSelect} name='item_image' className="form-control" type="file" />
                            </div>
                            <button disabled={!formik.isValid} type="submit" className="btn btn-save">Create Gallery <i className="fa-regular fa-floppy-disk"></i></button>
                        </form>
                    </div>
                </div>
            </div>
            <Modal show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered className=' modal-login'>
                <div className="modal-content content">
                    <div className="modal-body text-center">
                        <i className="fa-regular fa-circle-check text-success icon-check"></i>
                        <p className='text-center'> Gallery has been created!.</p>
                        <p className='text-center'> Please go to the next step.</p>
                    </div>
                    <div className="modal-footer footer-login-modal  d-md-block  text-center">
                        <NavLink to={`/step-images/${getIdResonse}`}><button type="button" className="btn btn-sm btn-send text-danger ">NEXT <i className="fa-solid fa-right-long"></i></button></NavLink>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export { NewGallery }