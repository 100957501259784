import React, { useContext, useState } from "react";

import http from "../configs/http.config";

class CategoryService {
    //dave one imgae
    saveImage(data, Authorization) {
        return http.post(`/gallery/image`, data, Authorization);
    }
    //save all images
    saveImageArr(id, data, Authorization) {
        return http.post(`/gallery/image_arr/${id}`, data, Authorization);
    }
    //get al imagge by id galerry
    getAllImagesGallery(id, Authorization) {
        return http.get(`/gallery/get_images/${id}`, Authorization);
    }
    //delete image
    deleteImageGallery(id, Authorization) {
        return http.delete(`/gallery/delete_image/${id}`, Authorization);
    }
    //new gallery 
    newGallery(data, Authorization) {
        return http.post(`/gallery/new`, data, Authorization);
    }
    getAllGallery(Authorization) {
        return http.get(`/gallery/get_cms`, Authorization);
    }
    getAllCategory(Authorization) {
        return http.get(`/gallery/get_category_cms`, Authorization);
    }
    getByIdGallery(id, Authorization) {
        return http.get(`/gallery/get_gallery_cms/${id}`, Authorization);
    }
    updateByIdGallery(id, data, Authorization) {
        return http.patch(`/gallery/update_gallery/${id}`,data, Authorization);
    }
    /*  getByIdGallery(id,Authorization) {
          return http.get(`/gallery/get_gallery_cms/${id}`,Authorization);
      }
      updateByIdGallery(id,data,Authorization) {
          return http.patch(`/gallery/update_gallery/${id}`,data,Authorization);
      } */


}
export default new CategoryService();