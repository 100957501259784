import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate, Link, NavLink, useParams, useLocation } from 'react-router-dom';

import { isMobile, isTablet } from 'react-device-detect';

//formik
import { Field, useFormik } from "formik";
import * as Yup from "yup";

//bootstrap
import Modal from 'react-bootstrap/Modal';

//services
import Videography from '../../../services/videography'
import PlaceService from '../../../services/place'


//auth
import { useAuth } from '../../../context/AuthProvider'
import category from '../../../services/category';
import videography from '../../../services/videography';
//validation form
const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required("*Required")
        .min(4, "Must be at least 4 characters")
        .max(45, "Cannot exceed 45 characters"),
    place: Yup.string()
        .required("*Required")
});


function NewVideograpy() {
    const logo = require('../../../assets/img/logo/logo.png');
    const imageDefault = require('../../../assets/img/design/image.png');

    //validation auth
    const auth = useAuth();

    //message error
    const [message, setMessage] = useState(null);
    //alert
    const [showAlert, setShowAlert] = useState(true);

    const [photo, setPhoto] = useState(null);
    const [video, setVideo] = useState(null);
    //video
    const [videoVideograpy, setVideoVideography] = useState('');
    const [thumbnailUrl, setThumbnailUrl] = useState(null);

    //places
    const [placeGet, setPlaceGet] = useState('');

    const location = useLocation();
    //back
    const navigate = useNavigate();
    function goBack() {
        navigate(-1);
    }

    //modal
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false) }
    const handleShow = () => setShow(true);

    useEffect(() => {
        onLoadPlace();
    }, [videoVideograpy]);

    //get first load places
    const onLoadPlace = async () => {
        try {
            let bearer_Token = {
                'headers': {
                    'Authorization': `Bearer ${auth.authToken()}`
                }
            };
            const getAllPlace = await PlaceService.getAllplace(bearer_Token);
            setPlaceGet(getAllPlace.data);
        } catch (error) {

        }
    }

    const handleVideoSelect = async (event) => {
        const maxVideoSizeMB = 100;  // Tamaño máximo permitido en MB
        const maxVideoWidth = 1080;  // Máximo ancho permitido
        const maxVideoHeight = 1080;
        const file = event.target.files[0];
        const captureTimeInSeconds = 5;
        let error = false;

        if (file && !/^video\/\w+$/.test(file.type)) {
            document.getElementById('item_video').value = '';
            setVideo(null);
            error = true;
            setMessage({ error: true, msg: 'The file must be a video' });
            return;
        }

        const maxSizeBytes = maxVideoSizeMB * 1024 * 1024;  // Convertir MB a bytes
        if (file && file.size > maxSizeBytes) {
            setVideo(null);
            error = true;
            setMessage({ error: true, msg: `The file must be less than or equal to ${maxVideoSizeMB} MB` });
            return;
        }
        

        setVideo(file);
        if (!error) {
            //handleSubmitVideo(file);
        }
        try {
            // Genera el thumbnail
            const thumbnailDataUrl = await generateThumbnail(file, captureTimeInSeconds,'360');
            const thumbnailPreview = document.getElementById('thumbnailPreview');
            thumbnailPreview.src = thumbnailDataUrl;
            //console.log(file, thumbnailDataUrl)
            handleSubmitVideo(file, thumbnailDataUrl);
        } catch (error) {
            console.error('Error al generar el thumbnail:', error);
        }
    };


    const generateThumbnail = (videoFile, captureTimeInSeconds, maxThumbnailSize) => {
        const video = document.createElement('video');
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
    
        return new Promise((resolve, reject) => {
            // Load the video
            video.src = URL.createObjectURL(videoFile);
            video.onloadedmetadata = () => {
                // Set the video to the specified capture time
                video.currentTime = captureTimeInSeconds;
    
                // Listen for the seeking event to ensure the video has seeked to the desired time
                video.onseeked = () => {
                    // Calculate thumbnail dimensions while maintaining aspect ratio
                    let thumbnailWidth = video.videoWidth;
                    let thumbnailHeight = video.videoHeight;
                    const aspectRatio = thumbnailWidth / thumbnailHeight;
    
                    if (thumbnailWidth > maxThumbnailSize || thumbnailHeight > maxThumbnailSize) {
                        if (aspectRatio > 1) {
                            thumbnailWidth = maxThumbnailSize;
                            thumbnailHeight = thumbnailWidth / aspectRatio;
                        } else {
                            thumbnailHeight = maxThumbnailSize;
                            thumbnailWidth = thumbnailHeight * aspectRatio;
                        }
                    }
    
                    // Set canvas dimensions to match the thumbnail dimensions
                    canvas.width = thumbnailWidth;
                    canvas.height = thumbnailHeight;
    
                    // Draw the frame at the desired time onto the canvas
                    context.drawImage(video, 0, 0, thumbnailWidth, thumbnailHeight);
    
                    // Convert the canvas content to a data URL representing a JPEG image
                    const thumbnailDataUrl = canvas.toDataURL('image/jpeg');
    
                    // Clean up resources
                    URL.revokeObjectURL(video.src);
    
                    // Resolve with the thumbnail data URL
                    resolve(thumbnailDataUrl);
                };
            };
    
            video.onerror = (error) => {
                // Clean up resources in case of an error
                URL.revokeObjectURL(video.src);
                reject(error);
            };
        });
    };    

    const handleSubmitVideo = async (file, thumbnailDataUrl) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('thumbnail', thumbnailDataUrl)
        if (file) {
            let bearer_Token = {
                'headers': {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${auth.authToken()}`
                }
            };
            try {
                const uploadImage = await Videography.createVideo(formData, bearer_Token);
                if (uploadImage.status === 200 && uploadImage.data.statusCode === 201) {
                    console.log('subir video y thumbnail',uploadImage)
                    setVideoVideography(uploadImage.data.vidUrl);
                    setThumbnailUrl(uploadImage.data.vidThumbnail);
                } else {
                    document.getElementById('item_image').value = '';
                    setShowAlert(true);
                    setMessage({ error: true, msg: "An error has occurred! try again" });
                }
            } catch (error) {
                console.log(error)
                if (error.response.status === 401) {
                    auth.logout()
                } else {
                    setShowAlert(true);
                    setMessage({ error: true, msg: "An error has occurred! try again" });
                }
            }
        }
    };
    const handleSubmit = async (values) => {
        const data = {
            name: values.name,
            place: values.place,
            active: values.active,
            video: videoVideograpy ? videoVideograpy : 'video',
            thumbnail: thumbnailUrl ? thumbnailUrl : 'thumbnail',
        };
        try {
            let bearer_Token = {
                'headers': {
                    'Authorization': `Bearer ${auth.authToken()}`
                }
            };
            const createBlog = await videography.createVideography(data, bearer_Token);
            console.log('subir blog',createBlog)
            if (createBlog.status === 200 && createBlog.data.statusCode === 201) {
                //create blog
                setShow(true);
            } else if (createBlog.status === 200 && createBlog.data.statusCode === 400) {
                //error
                setShowAlert(true);
                setMessage({ error: true, msg: "An error has occurred! try again" });
            } else if (createBlog.status === 200 && createBlog.data.output.statusCode === 401) {
                auth.logout()
            }
        } catch (error) {
            if (error.response.status === 401) {
                auth.logout()
            } else {
                setShowAlert(true);
                setMessage({ error: true, msg: "An error has occurred! try again" });
            }
        }
    }

    //formik
    const formik = useFormik({
        initialValues: { name: "", place: "", active: false, },
        validateOnBlur: true,
        onSubmit: (values) => {
            handleSubmit(values);
        },
        validationSchema: validationSchema,
    });


    return (
        <>
            <div className='container pd-4 content-cms'>
                <h4 className="text-center">NEW Videography</h4>
                <div className='row'>
                    <div className='text-center'>
                        {message ?
                            <div className={`alert alert-dismissible fade show alert-${message.error ? 'danger' : 'success'}`} role="alert">
                                <strong>{message.msg}</strong>
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                            : null
                        }
                    </div>
                    <div className='col-6'>
                        <div className="btn-group mb-3" role="group">
                            <Link onClick={goBack} type="button" className="btn btn-outline-secondary btn-sm"><i className="fa-solid fa-chevron-left"></i> Back</Link>
                        </div>
                    </div>
                    <div className='pd-4'>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="form-floating mb-3 col-md-12 col-lg-6">
                                <input type="text" className="form-control" placeholder="name@example.com"
                                    id="name" name='name'
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />
                                <label htmlFor="name">
                                    {formik.errors.name && formik.touched.name
                                        ? <div className="text-danger">Name {formik.errors.name}</div>
                                        : <div className="">Name </div>
                                    }
                                </label>
                            </div>
                            <div className="form-floating mb-3 col-md-12 col-lg-6">
                                <select className="form-select"
                                    id="place"
                                    name="place"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.place}
                                >
                                    <option value="">open this select menu</option>
                                    {Array.isArray(placeGet) ? (
                                        placeGet.map((item, i) => (
                                            <option key={i} value={item.plaId}>{item.plaName}</option>
                                        ))
                                    ) : (
                                        <option value="">No places available</option>
                                    )}
                                </select>
                                <label htmlFor="place">
                                    {formik.errors.place && formik.touched.place
                                        ? <div className="text-danger">Please, Select a place {formik.errors.place}</div>
                                        : <div className="">Please, Select a place </div>
                                    }
                                </label>
                            </div>
                            <div className="mb-3 col-md-12 col-lg-6">
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch"
                                        id="active"
                                        name='active'
                                        checked={formik.values.active}
                                        onChange={formik.handleChange} />
                                    <label className="form-check-label" htmlFor="active">active</label>
                                </div>
                            </div>
                            <div className="mb-3 col-md-12 col-lg-6">
                                <video key={videoVideograpy} className='img-fluid w-50' controls>
                                    <source src={videoVideograpy ? videoVideograpy : 'k'} type="video/mp4" />
                                </video>
                                <img id="thumbnailPreview" alt="Thumbnail Preview" />                            </div>
                            <div className="mb-5 ">
                                <label htmlFor="formFile" className="form-label">Please Select Video</label>
                                <input id='item_image' onChange={handleVideoSelect} name='item_image' className="form-control" type="file" accept="video/*" />
                            </div>
                            <button disabled={!formik.isValid} type="submit" className="btn btn-save">Create Videography <i className="fa-regular fa-floppy-disk"></i></button>
                        </form>
                    </div>
                </div>
            </div>
            <Modal show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered className=' modal-login'>
                <div className="modal-content content">
                    <div className="modal-body text-center">
                        <i className="fa-regular fa-circle-check text-success icon-check"></i>
                        <p className='text-center'> Viderography has been created!.</p>
                    </div>
                    <div className="modal-footer footer-login-modal  d-md-block  text-center">
                        <NavLink to={'/videography'}><button type="button" className="btn btn-sm btn-send ">Close <i className="fa-solid fa-xmark"></i></button></NavLink>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export { NewVideograpy }