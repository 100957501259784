import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Routes, Route, Link, NavLink, useParams, useLocation } from 'react-router-dom';

import { isMobile, isTablet } from 'react-device-detect';

//auth
import { useAuth } from '../../context/AuthProvider';

//pages
import { Category } from '../components/categories/category';
import { NewCategory } from '../components/categories/new';
import { EditCategory } from '../components/categories/edit';
import { Gallery } from '../components/galleries/gallery';
import { NewGallery } from '../components/galleries/new';
import { EditGallery } from '../components/galleries/edit';
import { Place } from '../components/places/place';
import { NewPlace } from '../components/places/new';
import { EditPlace } from '../components/places/edit';
import { StepImages } from '../components/galleries/stepImages';
import { Videography } from '../components/videography/videography';
import { NewVideograpy } from '../components/videography/new';

function Menu() {

    //images
    const logo = require('../../assets/img/logo/logo.png');
    const togglerBlack = require('../../assets/img/design/icon_menu_gray.png');
    const location = useLocation();

    //validation auth
    const auth = useAuth();

    const onLogout = () => {
        auth.logout()
    };
    return (
        <>
            <div className='menu'>
                <nav className="navbar bg-body-tertiary">
                    <div className="container-fluid">
                        <Link to={'/'} className="navbar-brand" href="#"><img src={logo} className='' /></Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                            <img src={togglerBlack} />
                        </button>
                        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                            <div className="offcanvas-header">
                                <h5 className="offcanvas-title" id="offcanvasNavbarLabel">CMS</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body">
                                <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                                    <li className="nav-item">
                                        <NavLink to={'/'} className="nav-link " aria-current="page" href="#">Home</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to={'/category'} className="nav-link" aria-current="page" >Categories</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to={'/gallery'} className="nav-link active" aria-current="page" href="#">galleries</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to={'/videography'} className="nav-link active" aria-current="page" href="#">videography</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to={'/place'} className="nav-link active" aria-current="page" href="#">places</NavLink>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Dropdown
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li>
                                                <hr className="dropdown-divider" />
                                            </li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                        </ul>
                                    </li>
                                    <hr />
                                    <li className="nav-item">
                                        <a className="nav-link" onClick={onLogout} >log out <i className="fa-solid fa-arrow-right-from-bracket"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <div className='content-cms bg-dark-footer '>
                <Routes>
                    <Route path="/category" element={<Category />} />
                    <Route path="/new-category" element={<NewCategory />} />
                    <Route path="/edit-category/:id" element={<EditCategory />} />
                    <Route path="/gallery" element={<Gallery />} />
                    <Route path="/new-gallery" element={<NewGallery />} />
                    <Route path="/edit-gallery/:id" element={<EditGallery />} />
                    <Route path="/place" element={<Place />} />
                    <Route path="/new-place" element={<NewPlace />} />
                    <Route path="/edit-place/:id" element={<EditPlace />} />
                    <Route path="/step-images/:id" element={< StepImages />} />
                    <Route path="/videography" element={< Videography />} />
                    <Route path="/new-videography" element={<NewVideograpy />} />


                   
                </Routes>
            </div>


        </>
    )
}
export { Menu }